.contenedora{
    align-items: "center";
}

  .cardHead {
    padding-top: 50; 
    padding-bottom :50;
    padding-left: 20;
    padding-right: 20;
  };
  .cardGeneral {
    padding-top: 50;
    padding-bottom: 50;
    padding-left: 20;
    padding-right: 20;
  };
  .card {
    margin-bottom: 10;          
  };
  .card_1 {
    border: "1px solid #000"
  };
  .card_2 {
    min-width: 275;
  };
  .card_3 {
    min-width: 275;
  };
  .card_3_c {
    border: "1px solid #000"
  };
  .card_f {
    min-width: 275;
  };
  .facturaHeader {
    border: "1px solid #000";
    margin:0;
  };
  .facturaBody {
    border: "1px solid #000"
    
  };
  .facturaFooter {
    
  };
  .table {
    min-width: 700;
  };
  .containerG{
    padding-left: 6;
    padding-right: 6;
  };
  .col {
    padding: 0
  };
  .colHead {
    padding: 0,
    
  };
  .facturaHead {
    border: "1px solid #000";
    margin:0;
    height: "8rem";
  };
  .facturaPosHead {
    border: "1px solid #000";
    margin:0;
    height: "9rem";
  };
  .logoGlobalNet{
    width: 150
  };