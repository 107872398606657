.headerO{
    border-bottom: ridge 0.1rem #0000002a;
}

.bodyO{
    padding-top: 0%;
    padding-bottom: 0%;
    border-top: ridge 0.1rem #0000002a;
    border-bottom: ridge 0.1rem #0000002a;
}

.footerO{
    border-top: ridge 0.1rem #0000002a;
}