
.divPadreLoading{
  height: 100vh;
  position: relative;
}

.divHijoLoading{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.spinnerReactstrap{
  width: 100% !important; 
  height: 100% !important;
  border: 10px solid currentColor !important;
  border-right-color: transparent !important;
}

.cardGeneral{
  width:100%;
}

.cardClienteEsp{
  border-right:solid #9e9e9e 0.07rem
}

.colorRojo{
  color: #d30000;
}
